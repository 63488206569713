@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  height: -webkit-fill-available;
  background-color: #fff;
}

* {
  font-family: "Poppins", sans-serif;
}

.react-tel-input .special-label {
  color: rgb(100 116 139);
  font-size: 12px !important;
  top: -8px !important;
  left: 7px !important;
}



.react-tel-input .form-control:focus {
  box-shadow: none !important;
  border-width: 2px!important;
  border-color: #0068fb;
}

.react-tel-input .form-control:invalid {
  box-shadow: none !important;
  border-width: 2px !important;
  border-color: rgb(239, 68, 68) !important;
}

.react-tel-input input.form-control {
  min-height: 50px;
  border-width: 1px;
  border-radius: 8px;
  border-color: #e5e7eb;
}

.special-label-error {
  color: rgb(239, 68, 68) !important;
}
.special-label-focus {
  color: rgb(59, 130, 246) !important;
}

@supports (-webkit-touch-callout: none) {
  .h-fill-available-ios {
    height: -webkit-fill-available;
  }
}

button,
label,
input,
textarea,
div,
select {
  -webkit-tap-highlight-color: transparent !important;
}
